













import { Component, Vue } from 'vue-property-decorator';
import ViewTitle from '@/components/ViewTitle.vue';

export default {
  components: {
    ViewTitle,
  },
  data() {
      return {
        partnerList: [
          { name: '宏基金融策劃有限公司' },
          { name: '華泰財務國際有限公司' },
          { name: '鼎好國際財務有限公司' },
          { name: '易提款財務有限公司' },
          { name: '易易壹財務有限公司' },
          { name: '利星行信貸有限公司' },
          { name: '億力信貸有限公司' },
          { name: '友通信貸有限公司' },
          { name: '德晉財務有限公司' },
          { name: '駿仁財務有限公司' },
          { name: '按揭360有限公司' },
          { name: '盛富財信貸財務' },
        ],
      };
  },
};
